@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;600;700&display=swap');
@import url('./typography.css');
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('./preflight-override.css');
@import url('react-quill/dist/quill.snow.css');

html {
  font-family: 'Inter', sans-serif;
  background-color: #f9fafb;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

.hide-scrollbar {
  /* hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.progress {
  animation: progress 1s infinite linear;
}

.left-right {
  transform-origin: 0% 50%;
}
@keyframes progress {
  0% {
    transform: translateX(0) scaleX(0);
  }
  40% {
    transform: translateX(0) scaleX(0.4);
  }
  100% {
    transform: translateX(100%) scaleX(0.5);
  }
}

.rich-text > ul > li {
  list-style-type: disc;
  display: list-item;
  list-style-position: inside;
}

.rich-text > ol > li {
  list-style-type: decimal;
  display: list-item;
  list-style-position: inside;
}

.ant-btn-icon {
  line-height: 1;
}

.rich-text {
  h1 {
    font-size: 2rem;
    word-wrap: break-word;
    margin: 4px 0;
  }
  h2 {
    font-size: 1.5rem;
    word-wrap: break-word;
    margin: 3px 0;
  }
  h3 {
    font-size: 1.17rem;
    word-wrap: break-word;
    margin: 3px 0;
  }
  a {
    color: #3182ce;
    text-decoration: underline;
    word-wrap: break-word;
    margin: 2px 0;
  }
  ul,
  ol {
    margin: 2px 0;
  }
}

.custom-checkbox-group .ant-checkbox-inner {
  transform: scale(1.5);
  transform-origin: left;
  margin-right: 6px;
}

.animated-border {
  border: 3px solid transparent;
  border-radius: 24px;
  border-image-source: linear-gradient(90deg, darkviolet, blue);
  border-image-slice: 1;
  background-clip: padding-box;
  animation: borderAnimation1 3s infinite alternate ease-in-out;
}

@keyframes borderAnimation1 {
  0% {
    border-image-source: linear-gradient(90deg, darkviolet, blue);
  }
  100% {
    border-image-source: linear-gradient(90deg, blue, darkviolet);
  }
}

.hearlink-ai-mask {
  width: 56px;
  height: 18px;
  background: linear-gradient(45deg, blue, darkviolet);
  background-size: 400% 400%;
  animation: gradientAnimation 5s ease infinite;
  -webkit-mask-image: url('../assets/images/hearlink-ai.svg');
  mask-image: url('../assets/images/hearlink-ai.svg');
  mask-size: cover;
  -webkit-mask-size: cover;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
